import { graphql, useStaticQuery } from "gatsby"
import React, { useState } from 'react'
import BlogRoll from '../../components/BlogRoll'
import Layout from '../../components/Layout'
import TitleImage from '../../components/TitleImage'

const BlogIndexPage = () => {
  const { allImageSharp: { edges: [{ node: {fluid: titleImage} }] }, allFile: newsletters} = useStaticQuery(graphql`
    {
      allImageSharp(filter: {fluid: {originalName: {eq: "man_with_cart.jpg"}}}) {
        edges {
          node {
            fluid(maxWidth: 2048, quality: 100) {
              src
              aspectRatio
              originalName
            }
          }
        }
      }
      allFile(filter: { extension: { eq: "pdf" }, sourceInstanceName: {eq: "newsletters"} }) {
        edges {
          node {
            name
          }
        }
      }
    }
  `);

  const [currentTab, setCurrentTab] = useState('latestStories');

  const isActiveTabClass = (tab) => {
    return tab === currentTab ? "is-active" : "";
  };

  const issueNumber = (file) => { return file.node.name.split("_")[0] };
  const newsletterListItems = newsletters.edges
    .sort((a,b) => issueNumber(b) - issueNumber(a))
    .map((file) => {
      const name = file.node.name;
      const year = name.split("_")[1];
      return (
        <a 
          className="panel-block is-active newsletter-list-item"
          href={`/newsletters/${name}.pdf`} 
          target="_blank"
          key={name}
          rel="noopener noreferrer"
        >
          <span className="panel-icon">
            <i className="fas fa-book" aria-hidden="true"></i>
          </span>
          <span>{`Issue ${issueNumber(file)}, ${year}`}</span>
        </a>
      )
    });
  const newsletterView = (
    <div className="container column is-6">
      <article className="panel is-success">
        {newsletterListItems}
      </article>
    </div>
  );

  const latestStoriesView = (
    <div className="container column is-10">
      <div className="content">
        <BlogRoll />
      </div>
    </div>
  );

  const videos = [
    { description: "A short introduction to BEHT from 2008", link: "https://youtu.be/KyNXiQGJ4g8"},
    { description: "..and another one from 2009", link: "https://youtu.be/7VIgEzsrzPI"},
    { description: "An interview with Bapu from 2013", link: "https://vimeo.com/82241577"},
  ]

  const videoItems = videos.map((video) => {
    return (
      <a 
        className="panel-block is-active video-link"
        href={video.link} 
        target="_blank"
        rel="noopener noreferrer"
      >
        <span className="panel-icon">
          <i className="fas fa-film" aria-hidden="true"></i>
        </span>
        <span>{video.description}</span>
      </a>
    )
  })

  const videosView = (
    <div className="container column is-6">
      <article className="panel is-success">
        {videoItems}
      </article>
    </div>
  );

  const renderView = () => {
    switch(currentTab) {
      case 'newsletters':
        return newsletterView;
      case 'videos':
        return videosView;
      default:
        return latestStoriesView;
    }
  };

  return (
    <Layout>
      <TitleImage title={'News'} image={titleImage} />
      <div className="tabs is-centered is-size-7-mobile">
        <ul>
          <li className={isActiveTabClass("latestStories")} onClick={() => setCurrentTab("latestStories")}>
            <a>
              <span className="icon is-small"><i className="fas fa-file-alt" aria-hidden="true"></i></span>
              <span>Latest Stories</span>
            </a>
          </li>
          <li className={isActiveTabClass("newsletters")} onClick={() => setCurrentTab("newsletters")}>
            <a>
              <span className="icon is-small"><i className="fas fa-book" aria-hidden="true"></i></span>
              <span>Newsletters</span>
            </a>
          </li>
          <li className={isActiveTabClass("videos")} onClick={() => setCurrentTab("videos")}>
            <a>
              <span className="icon is-small"><i className="fas fa-film" aria-hidden="true"></i></span>
              <span>Videos</span>
            </a>
          </li>
        </ul>
      </div>
      <section className="section columns is-variable is-1 mb-2">
        {renderView()}
      </section>
    </Layout>
  )
}

export default BlogIndexPage

